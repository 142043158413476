import React, {Component} from "react";
import {IProps, IState} from "./types";
import {connect} from "react-redux";
import {State, Vitals} from "../../state/types";
import {Client} from "../../client/axios";
import LoaderComponent from "../loader/component";

const mapStateToProps = (state: State) => {
    return {
        title: "Vitals",
        vitals: state.vitals
    }
}

export class VitalsComponent extends Component<IProps,IState> {
    constructor(props : IProps){
        super(props)
        this.state = {
            loader: true
        }
    }

    componentDidMount() {
        let client : Client = new Client();
        let _ = client.getVitals().then(() => this.setState({loader: false}));
    }

    render() {
        return (
            <div className="flex flex-col h-full bg-gradient-to-br from-slate-800 to-slate-900 text-xl p-8">
                <LoaderComponent visible={this.state.loader} icon="hypnotize">
                    <div className="text-red-500 flex-grow flex items-center justify-between">
                        <div className="font-bold flex-grow whitespace-nowrap"><i className="bi bi-heart-fill pr-1"></i>Heart</div>
                        <div className="h-1 w-full bg-red-500 mx-2 rounded"></div>
                        <div className="whitespace-nowrap">{this.props.vitals.heartRate} BPM</div>
                    </div>
                    <div className="text-green-500 flex-grow flex items-center justify-between">
                        <div className="font-bold whitespace-nowrap"><i className="bi bi-sign-turn-slight-right-fill pr-1"></i>Distance</div>
                        <div className="h-1 w-full bg-green-500 mx-2 rounded"></div>
                        <div className="whitespace-nowrap">{this.props.vitals.walkingAndRunningDistance} KM</div>
                    </div>
                    <div className="text-orange-500 flex-grow flex items-center justify-between">
                        <div className="font-bold whitespace-nowrap"><i className="bi bi-fire pr-1"></i>Energy</div>
                        <div className="h-1 w-full bg-orange-500 mx-2 rounded"></div>
                        <div className="whitespace-nowrap">{this.props.vitals.activityEnergy} KJ</div>
                    </div>
                    <div className="text-blue-500 flex-grow flex items-center justify-between">
                        <div className="font-bold whitespace-nowrap"><i className="bi bi-lungs-fill pr-1"></i>Fitness</div>
                        <div className="h-1 w-full bg-blue-500 mx-2 rounded"></div>
                        <div className="whitespace-nowrap">{this.props.vitals.cardioFitness} VO<sub>2</sub></div>
                    </div>
                    <div className="text-yellow-500 flex-grow flex items-center justify-between">
                        <div className="font-bold whitespace-nowrap"><i className="bi bi-ear-fill pr-1"></i>Sound</div>
                        <div className="h-1 w-full bg-yellow-500 mx-2 rounded"></div>
                        <div className="whitespace-nowrap">{this.props.vitals.heartRate} DB</div>
                    </div>
                </LoaderComponent>
            </div>
        )
    }
}

export default connect(mapStateToProps)(VitalsComponent);