import React, {Component} from "react";
import {IProps, IState} from "./types";
import {Client} from "../../client/axios";

export default class LinkedInComponent extends Component<IProps,IState> {
    constructor(props : IProps){
        super(props)
    }

    componentDidMount() {}

    render() {
        return (
            <a className="flex flex-col justify-between h-full p-8 bg-gradient-to-br from-blue-500 to-blue-600 text-white" href="google.com">
                <div className="text-9xl opacity-25">
                    <i className="bi bi-linkedin"></i>
                </div>
                <div className="text-xl">
                    <div className="leading-none font-bold">Software Engineer</div>
                    <div className="leading-none">Deloitte</div>
                </div>
            </a>
        )
    }
}