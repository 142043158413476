import React from 'react';
import logo from './logo.svg';
import './App.css';
import VitalsComponent from "./view/vitals/component";
import RecentlyPlayedComponent from "./view/recently-played/component";
import LocationComponent from "./view/location/component";
import WeatherComponent from "./view/weather/component";
import TimeComponent from "./view/time/component";
import Sticker from "./view/sticker/component";
import NoteComponent from "./view/note/component";
import LinkedInComponent from "./view/linked-in/component";
import ProficiencyComponent from "./view/proficiency/component";
import LoaderComponent from "./view/loader/component";

function App() {
    return (
        <div className="flex flex-col justify-center items-center w-screen bg-gradient-to-br from-stone-800 to-stone-900 p-8">
            <div className="max-w-screen-xl min-h-screen">
                <div className="text-6xl font-bold w-full pb-4 pl-16 text-white-smoke">Lachlan Edwards</div>
                <div className="w-full">
                    <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:-gap-x-6 lg:-gap-y-6 md:-gap-y-4 md:-gap-x-4 -gap-y-2 -gap-x-0">
                        <Sticker className="lg:col-span-2 col-span-1 row-span-1 lg:aspect-w-2 aspect-w-1 aspect-h-1 lg:pb-grid-x-2-gap-6 rotate-6">
                            <LocationComponent/>
                        </Sticker>
                        <Sticker className="col-span-1 row-span-1 aspect-1 rounded-4xl overflow-hidden border-2 -rotate-6">
                            <WeatherComponent/>
                        </Sticker>
                        <Sticker className="col-span-1 row-span-1 aspect-1 rotate-6">
                            <TimeComponent/>
                        </Sticker>
                        <Sticker className="col-span-1 row-span-1 aspect-1 -rotate-6">
                            <RecentlyPlayedComponent/>
                        </Sticker>
                        <Sticker className="col-span-1 row-span-1 aspect-1 rotate-6">
                            <VitalsComponent/>
                        </Sticker>
                        <Sticker className="lg:col-span-2 col-span-1 row-span-1 lg:aspect-w-2 aspect-w-1 aspect-h-1 lg:pb-grid-x-2-gap-6 -rotate-6">
                            <NoteComponent/>
                        </Sticker>
                        <Sticker className="col-span-1 row-span-1 aspect-1 rotate-6">
                            <LinkedInComponent/>
                        </Sticker>
                        <Sticker className="col-span-1 row-span-1 aspect-1 -rotate-6">
                            <ProficiencyComponent strings={[
                                "git add",
                                "*.js",
                                "*.ts",
                                "*.sql",
                                "*.cs",
                                "*.py",
                                "*.java",
                                "*.swift",
                                "*.tf",
                                "*.bicep",
                                "*.html",
                                "*.css",
                                "*.ai",
                                "*.idd",
                                "*.psd",
                                "*.ae",
                                "*.sketch",
                                "*.xd"
                            ]}/>
                        </Sticker>
                    </div>
                </div>
                <div className="text-white text-center text-sm uppercase pt-8">
                    <a href='https://www.mapbox.com/about/maps/' target='_blank'>Maps &copy; Mapbox &copy; OpenStreetMap</a>
                    <div>I acknowledge and pay respect to the past, present and future Traditional Custodians and Elders of this nation and the continuation of cultural, spiritual and educational practices of Aboriginal and Torres Strait Islander peoples.</div>
                </div>
            </div>
        </div>
    );
}

export default App;
