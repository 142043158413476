import React, {Children, Component, createRef} from 'react';
import {IProps} from "./types";

export default class Sticker extends Component<IProps> {
    constructor(props: IProps) {
        super(props);
    }
    render() {
        return (
            <div className={`border-8 border-white rounded-4xl overflow-hidden ${this.props.className} shadow-lg`}>
                {this.props.children}
            </div>
        );
    }
}