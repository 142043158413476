import axios, { AxiosInstance, AxiosResponse } from 'axios';
import {Commit, VitalsResponse, PipelineAggregation} from "./types";
import {
    SpotifyRecentlyPlayed,
    SpotifyItem,
    SpotifyTrack,
    SpotifyAlbum,
    SpotifyArtist,
    SpotifyLastPlayed
} from "../models/spotify";
import {WeatherResponse} from "../models/weather";
import store from "../state/store";
import {setLastPlayedContainer, setVitals, setWeather} from "../state/reducer";
import {TextEncoder} from "text-encoding";

export class Client {

    protected readonly instance: AxiosInstance;

    constructor() {
        this.instance = axios.create({
            baseURL: "https://portfolio-ae-np-in01.azurewebsites.net/",
            timeout: 15000
        });
    }

    fetch_commit():Promise<Commit> {
        return new Promise((resolve, reject) => {
            this.instance.get<Commit>("/GitHub/latest_commit").then(response => {
                let commit:Commit = response.data;
                if (commit) {
                    resolve(commit);
                } else {
                    throw new Error("Unexpected response")
                }
            }).catch(error => {
                reject(error);
            })
        })
    }

    fetch_pipelines():Promise<PipelineAggregation> {
        return new Promise((resolve, reject) => {
            this.instance.get<PipelineAggregation>("/azure/pipelines").then(response => {
                let aggregation:PipelineAggregation = response.data;
                if (aggregation) {
                    resolve(aggregation);
                } else {
                    throw new Error("Unexpected response")
                }
            }).catch(error => {
                reject(error);
            })
        })
    }

    getVitals():Promise<VitalsResponse> {
        return new Promise((resolve, reject) => {
            this.instance.get<VitalsResponse>("/Activity/latest_activity").then(response => {
                let vitals: VitalsResponse = response.data;
                if (vitals) {
                    store.dispatch(setVitals(vitals));
                    resolve(vitals);
                } else {
                    throw new Error("Unexpected response")
                }
            }).catch(error => {
                reject(error);
            })
        })
    }

    getLastPlayed():Promise<SpotifyItem | null> {
        return new Promise((resolve, reject) => {
            this.instance.get<SpotifyRecentlyPlayed>("/Spotify/last_played").then(response => {
                let recentlyPlayed: SpotifyRecentlyPlayed = response.data;
                if (recentlyPlayed && recentlyPlayed.items && recentlyPlayed.items.length > 0) {
                    let item = recentlyPlayed.items[0];
                    store.dispatch(setLastPlayedContainer(item));
                    resolve(item);
                } else {
                    resolve(null);
                }
            }).catch(error => {
                reject(error);
            })
        })
    }

    getWeather():Promise<WeatherResponse | null> {
        return new Promise((resolve, reject) => {
            this.instance.get<WeatherResponse>("/weather/current").then(response => {
                let weather: WeatherResponse = response.data;
                if (weather) {
                    store.dispatch(setWeather(weather));
                    resolve(weather);
                } else {
                    resolve(null);
                }
            }).catch(error => {
                reject(error);
            })
        })
    }

    getAudioBuffer(spotifyItem: SpotifyItem):Promise<AudioBuffer | null> {
        return new Promise((resolve, reject) => {
            this.instance.get<ArrayBuffer>(spotifyItem.track.preview_url, {
                responseType: "arraybuffer"
            }).then(async response => {
                let audioContext: AudioContext = new AudioContext();
                let buffer: ArrayBuffer = response.data;
                if (buffer) {
                    let audioBuffer: AudioBuffer = await audioContext.decodeAudioData(buffer);
                    resolve(audioBuffer);
                } else {
                    resolve(null);
                }
            }).catch(error => {
                reject(error);
            })
        })
    }
}