import {RecentlyPlayed, State, Vitals, Weather} from "./types";
import {combineReducers, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {SpotifyItem} from "../models/spotify";
import {VitalsResponse} from "../client/types";
import {WeatherResponse} from "../models/weather";

class Reducer {
    static initial: State = {
        lastPlayed: {} as RecentlyPlayed,
        vitals: {} as Vitals,
        weather: {} as Weather,
    };
    static slice = createSlice({
        name: "default",
        initialState: this.initial,
        reducers: {
            setLastPlayedContainer: (state, action: PayloadAction<SpotifyItem>) => {
                state.lastPlayed = {
                    playedAt: action.payload.played_at,
                    title: action.payload.track.name,
                    artist: action.payload.track.artists[0].name,
                    albumImageUrl: action.payload.track.album.images[0].url,
                    previewUrl: action.payload.track.preview_url
                }
                return state;
            },
            setVitals: (state, action: PayloadAction<VitalsResponse>) => {
                state.vitals = {
                    activityEnergy: Math.round(+action.payload.activityEnergy),
                    walkingAndRunningDistance: Math.round(+action.payload.walkingAndRunningDistance),
                    heartRate: Math.round(+action.payload.heartRate),
                    cardioFitness: Math.round(+action.payload.cardioFitness),
                    environmentalSoundLevels: Math.round(+action.payload.environmentalSoundLevels)
                }
                return state;
            },
            setWeather: (state, action: PayloadAction<WeatherResponse>) => {
                state.weather = {
                    temp: (action.payload.main.temp - 273.15).toFixed(0),
                    description: action.payload.weather[0].main,
                    id: action.payload.weather[0].id,
                    location: action.payload.name
                }
                return state;
            }
        }
    })
}

export default Reducer.slice;
export const { setLastPlayedContainer, setVitals, setWeather } = Reducer.slice.actions