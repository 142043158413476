import React, { Component } from 'react';
import mapboxgl from 'mapbox-gl';
import {IProps, IState} from "./types";
import {connect} from "react-redux";
import {State} from "../../state/types";
import {Client} from "../../client/axios";
import LoaderComponent from "../loader/component";
mapboxgl.accessToken = 'pk.eyJ1IjoibWV0bGVhc2UiLCJhIjoiY2pyYzA5ZW1sMThhdjN5cG45N24wZzk3aCJ9.sgPujNuDMhFzEElX-P83aQ';

const mapStateToProps = (state: State) => {
    return {
        title: "Weather",
        temp: state.weather?.temp,
        description: state.weather?.description,
        icon: WeatherComponent.getIcon(state.weather?.id),
        location: state.weather?.location
    }
}

class WeatherComponent extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            loader: true
        }
    }

    componentDidMount(): void {
        let client : Client = new Client();
        let _ = client.getWeather().then(() => this.setState({loader: false}));
    }

    static getIcon(id: number | undefined): string {
        if (!id) return "sun";

        switch (true) {
            case id <=200: return "cloud-lightning-rain-fill";
            case id <= 300: return "cloud-drizzle-fill";
            case id <= 500: return "cloud-rain-fill";
            case id <= 600: return "cloud-snow-fill";
            case id <= 700: return "cloud-haze2-fill";
            case id == 800: return "sun";
            case id > 800: return "cloudy-fill";
        }

        return "sun"
    }

    render() {
        return (
            <div className="flex flex-col w-full h-full p-8 bg-gradient-to-br from-blue-800 to-blue-900 text-white">
                <LoaderComponent visible={this.state.loader} icon="hypnotize">
                    <div className="text-xl"><i className="bi bi-geo-fill"></i> {this.props.location}</div>
                    <div className="text-8xl flex-grow font-light">{this.props.temp}º</div>
                    <div className="text-xl"><i className={`bi bi-${this.props.icon}`}></i> {this.props.description}</div>
                </LoaderComponent>
            </div>
        );
    }
}

export default connect(mapStateToProps)(WeatherComponent);