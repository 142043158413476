import React, {Component, createRef} from 'react';
import mapboxgl from 'mapbox-gl';
import {IProps, IState} from "./types";
mapboxgl.accessToken = 'pk.eyJ1IjoibWV0bGVhc2UiLCJhIjoiY2pyYzA5ZW1sMThhdjN5cG45N24wZzk3aCJ9.sgPujNuDMhFzEElX-P83aQ';

class LocationComponent extends Component<IProps, IState> {
    private mapContainer: React.RefObject<HTMLDivElement> = createRef<HTMLDivElement>();
    constructor(props: IProps) {
        super(props);
        this.state = {
            zoom: 12,
            lng: 144.9626291,
            lat: -37.8138508,
            style: "mapbox://styles/mapbox/dark-v11",
        }
    }
    render() {
        return (
            <div className="w-full h-full">
                <div className="w-full h-full flex justify-center items-center">
                    <div className="animate-ping bg-blue-400 bg-opacity-30 border rounded-full border-blue-400 z-10 w-12 h-12 flex justify-center items-center" style={{animationDuration: "2s"}}></div>
                    <div className="bg-blue-400 w-6 h-6 border-4 rounded-full border-white z-10 right-9 relative shadow-lg"></div>
                </div>
                <div ref={this.mapContainer} className="map-container h-full w-full relative -top-full rounded-xxl overflow-hidden" />
            </div>
        );
    }
    componentDidMount(): void {
        const { style, lng, lat, zoom } = this.state;
        let mapContainer : HTMLDivElement | null = this.mapContainer.current;
        if (mapContainer) {
            const map = new mapboxgl.Map({
                container: mapContainer,
                style: style,
                center: [lng, lat],
                zoom: zoom,
                scrollZoom: {
                    around: "center"
                },
                dragPan: false,
                dragRotate: false,
                keyboard: false,
                attributionControl: false
            });
        }
    }
}

export default LocationComponent;