import React, {Component} from "react";
import {IProps, IState} from "./types";
import {Client} from "../../client/axios";

export default class NoteComponent extends Component<IProps,IState> {
    constructor(props : IProps){
        super(props)
    }

    componentDidMount() {}

    render() {
        return (
            <div className="flex flex-col h-full">
                <div className="flex items-center p-8 bg-gradient-to-br from-yellow-400 to-yellow-500 font-bold text-xl">
                    Untitled
                </div>
                <div className="p-8 bg-gradient-to-br from-gray-100 to-gray-200 h-full text-xl">
                    I studied a Bachelor of Information Technology majoring in Computer Science and a Bachelor of Fine Arts majoring in Interactive and Visual Design.
                </div>
            </div>
        )
    }
}