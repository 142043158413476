import React, {Component} from "react";
import {IProps, IState} from "./types";

export default class LoaderComponent extends Component<IProps,IState> {
    constructor(props : IProps){
        super(props)
    }

    componentDidMount() {}

    render() {
        if (this.props.visible) {
            return (
                <div className="h-full w-full flex items-center justify-center flex-none text-white text-2xl">
                    <i className={`bi bi-${this.props.icon} animate-spin leading-[0]`}></i>
                </div>
            )
        } else {
            return(this.props.children)
        }
    }
}