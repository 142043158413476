import React, {Component, ReactDOM} from 'react';
import {IProps, IState} from "./types";

class ProficiencyComponent extends Component<IProps, IState> {
    private text_area: any;
    private scroll_container: any;

    constructor(props: IProps) {
        super(props);
        this.state = {
            text: ""
        }
        this.text_area = React.createRef();
        this.scroll_container = React.createRef();
    }
    componentDidMount(): void {
        this.type();
    }
    render() {
        return (
            <div className="p-6 bg-gradient-to-br from-neutral-950 to-neutral-800 text-white w-full h-full overflow-scroll text-xl" ref={this.scroll_container}>
                <div className="font-mono whitespace-pre-wrap" ref={this.text_area}></div>
                <div className="font-mono">{`>${this.state.text}`}</div>
            </div>
        );
    }
    private timeout(method: Function, ms: number) {
        return new Promise(resolve => {
            setTimeout(() => {
                method();
                resolve(null);
            }, ms);
        })
    }
    async type() {
        for (let string of this.props.strings) {
            string += " ";
            for (let letter of string) {
                let func = (letter: string) => this.setState({
                    text: this.state.text + letter
                });
                await this.timeout(func.bind(this, letter), 100)
            }
            this.scroll_container.current.scrollTop = this.scroll_container.current.scrollHeight;
        }
        this.text_area.current.append(`${this.state.text} `);
        this.setState({
            text: ""
        });
    }
}

export default ProficiencyComponent;