import slice from "./reducer";

import { configureStore } from '@reduxjs/toolkit'

class Store {
    static store = configureStore({
        reducer: slice.reducer
    })
}

export default Store.store
