import React, { Component } from 'react';
import {IProps, IState} from "./types";
export default class TimeComponent extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            hour: 0,
            minute: 0,
            second: 0
        }
    }

    componentDidMount(): void {
        setInterval(() => this.setDateTime(), 100)
    }

    setDateTime() {
        let formatter = new Intl.DateTimeFormat([], {
            timeZone: "Australia/Melbourne",
            year: undefined,
            month: undefined,
            day: undefined,
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
        });
        let parts = formatter.formatToParts(new Date())
        this.setState({
            hour: parseInt(parts.find(i => i.type == 'hour')!.value),
            minute: parseInt(parts.find(i => i.type == 'minute')!.value),
            second: parseInt(parts.find(i => i.type == 'second')!.value)
        })
    }

    render() {
        return (
            <div className="grid grid-rows-1 grid-cols-1 justify-center w-full h-full bg-gradient-to-br from-emerald-800 to-emerald-900 text-white">
                <div className="flex flex-col justify-end items-center col-start-1 row-start-1">
                    <div className="mb-8 p-1 backdrop-blur rounded border-2 border-white">
                        <div className="text-xl leading-none">{('0' + this.state.hour).slice(-2)}:{('0' + this.state.minute).slice(-2)}:{('0' + this.state.second).slice(-2)}</div>
                    </div>
                </div>
                <div className="grid grid-rows-1 grid-cols-1 col-start-1 row-start-1">
                    <div className="flex justify-center items-center col-start-1 row-start-1 z-10">
                        <div className="rounded-full w-2 h-2 bg-emerald-100"></div>
                    </div>
                    <div className="flex justify-center items-center col-start-1 row-start-1">
                        <div className="h-1/5 w-1 bg-emerald-500 rounded origin-bottom" style={{rotate: `${(this.state.hour / 24 * 360).toFixed(0)}deg`, marginBottom: "20%"}}></div>
                    </div>
                    <div className="flex justify-center items-center col-start-1 row-start-1">
                        <div className="h-1/4 w-1 bg-emerald-400 rounded origin-bottom" style={{rotate: `${(this.state.minute / 60 * 360).toFixed(0)}deg`, marginBottom: "25%"}}></div>
                    </div>
                    <div className="flex justify-center items-center col-start-1 row-start-1">
                        <div className="h-1/3 w-1 bg-emerald-300 rounded origin-bottom" style={{rotate: `${(this.state.second / 60 * 360).toFixed(0)}deg`, marginBottom: "33.333%"}}></div>
                    </div>
                </div>
            </div>
        );
    }
}