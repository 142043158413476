import React, {Component, createRef, ReactEventHandler, SyntheticEvent, useRef} from "react";
import {IProps, IState} from "./types";
import {connect} from "react-redux";
import {State, Vitals} from "../../state/types";
import {Client} from "../../client/axios";
import {SpotifyItem} from "../../models/spotify";
import LoaderComponent from "../loader/component";

const mapStateToProps = (state: State) => {
    return {
        title: "Recently Played",
        recentlyPlayed: state.lastPlayed
    }
}

export class RecentlyPlayedComponent extends Component<IProps,IState> {
    private htmlAudioElement: React.RefObject<HTMLAudioElement> = createRef<HTMLAudioElement>();
    private svgCircleElement: React.RefObject<SVGCircleElement> = createRef<SVGCircleElement>();
    private htmlDivElement: React.RefObject<HTMLDivElement> = createRef<HTMLDivElement>();

    constructor(props : IProps) {
        super(props)
        this.state = {
            elapsed: 0,
            remaining: 0,
            duration: 0,
            paused: true,
            buffer: null,
            loader: true,
            visualized: false
        }
    }

    componentDidMount() {
        let client : Client = new Client();
        client.getLastPlayed().then((spotifyItem: SpotifyItem | null)  => {
            return client.getAudioBuffer(spotifyItem!);
        }).then((buffer: AudioBuffer | null) => {
            this.setState({buffer: buffer, loader: false})
        });
    }

    onTimeUpdate(e: SyntheticEvent<HTMLAudioElement>) {
        let remaining = e.currentTarget.duration - e.currentTarget.currentTime;
        this.setSvgCircleStrokeDashOffset()
        this.setState({
            elapsed: e.currentTarget.currentTime,
            remaining: remaining,
            duration: e.currentTarget.duration
        });

        if (remaining == 0) this.setState({paused: true})
    }

    setSvgCircleStrokeDashOffset() {
        let svgCircleElement = this.svgCircleElement.current;
        let duration = this.state.duration > 0
            ? +(this.state.elapsed / this.state.duration * 100).toFixed(0)
            : 0;

        if (svgCircleElement) {
            let radius: number = 56;
            let circumference: number = 2 * Math.PI * radius;

            let position: number = duration / 100;
            let dashOffset = circumference * (1 - position);

            svgCircleElement.style.strokeDasharray = `${circumference}`;
            svgCircleElement.style.strokeDashoffset = `${dashOffset}`;
        }
    }

    visualize(e: HTMLDivElement) {
        let htmlCanvasElement: HTMLCanvasElement = document.createElement('canvas');
        let htmlDivElement: HTMLDivElement | null = this.htmlDivElement.current;
        let buffer: AudioBuffer | null = this.state.buffer;

        if (!htmlCanvasElement || !htmlDivElement || !buffer ) return;

        let graphicWidth = e.clientWidth * 2;
        let graphicHeight = e.clientHeight * 2;

        htmlCanvasElement.width = graphicWidth;
        htmlCanvasElement.height = graphicHeight;

        let data: Float32Array = buffer.getChannelData( 0 );
        let step: number = Math.ceil( data.length / graphicWidth );
        let amplitude: number = graphicHeight / 2;
        let render: CanvasRenderingContext2D | null = htmlCanvasElement.getContext('2d');

        if (!render) return;

        let width = 2;
        let increment = 4;

        for (let i = 0; i < graphicWidth; i += increment) {
            let min: number = 1.0;
            let max: number = -1.0;
            for (let j = 0; j < step; j++) {
                let datum: number = data[(i*step)+j];
                if (datum < min) min = datum;
                if (datum > max) max = datum;
            }
            let height = (Math.max(1,(max-min)*amplitude));

            render.fillStyle = "rgba(255, 255, 255, 0.5)";
            render.beginPath();
            render.roundRect(i,(1+min)*amplitude,width, height, width);
            render.fill();
        }

        htmlDivElement.style.backgroundImage = `url('${htmlCanvasElement.toDataURL()}')`;
    }

    pause() {
        let htmlAudioElement: HTMLAudioElement | null = this.htmlAudioElement.current;
        let htmlDivElement: HTMLDivElement | null = this.htmlDivElement.current;

        if (!this.state.visualized && htmlDivElement) {
            new ResizeObserver((e) => this.visualize(e[0].target as HTMLDivElement)).observe(htmlDivElement)
            this.setState({visualized: true})
        }

        if (htmlAudioElement) {
            htmlAudioElement.paused ? htmlAudioElement.play() : htmlAudioElement.pause();
            this.setState({paused: htmlAudioElement.paused});
        }
    }

    timer(input: number) {
        let min: number = Math.floor(input / 60);
        let sec: number = +(input - min * 60).toFixed(0);
        return min + ":" + (sec < 10 ? '0' : '') + sec;
    }

    getProgression(elapsed: number, duration: number) {
        return duration > 0 ? (elapsed / duration * 100).toFixed(0) : 0;
    }

    render() {
        return (
            <div className="bg-gradient-to-br from-violet-800 to-violet-900 h-full">
                <LoaderComponent visible={this.state.loader} icon="hypnotize">
                    <div className="h-full flex flex-col items-center justify-center">
                        <div className="h-2/3 flex justify-center">
                            <div className="grid grid-cols-3 grid-rows-3 bg-cover bg-center max-h-full aspect-1" style={{backgroundImage: `url('${this.props.recentlyPlayed.albumImageUrl}')`}}>
                                <svg className="progress row-start-2 col-start-2 z-10 cursor-pointer -rotate-90"
                                     width="100%"
                                     height="100%"
                                     viewBox="0 0 120 120"
                                     onClick={() => this.pause()}>
                                    <circle className="stroke-white fill-none transition-[stroke-dashoffset] duration-1000 ease-linear"
                                            cx="60"
                                            cy="60"
                                            r="56"
                                            strokeWidth="5"
                                            strokeLinecap="round"
                                            strokeDasharray="351.858377202"
                                            strokeDashoffset="351.858377202"
                                            ref={this.svgCircleElement}/>
                                </svg>
                                <div className="flex items-center justify-center backdrop-blur-3xl col-start-2 row-start-2 rounded-full text-white">
                                    {this.state.paused ? <i className="bi bi-play-fill leading-none"></i> : <i className="bi bi-pause-fill leading-none"></i>}
                                </div>
                            </div>
                        </div>
                        <div className="w-2/3 text-white mt-2 flex gap-2">
                            <div className="w-1/2">
                                <div className="leading-none font-bold whitespace-nowrap overflow-hidden overflow-ellipsis">{this.props.recentlyPlayed.title}</div>
                                <div className="leading-none whitespace-nowrap overflow-hidden overflow-ellipsis">{this.props.recentlyPlayed.artist}</div>
                            </div>
                            <div className={`bg-center bg-contain w-2/3 text-white flex-grow transition-transform ${this.state.visualized ? 'scale-100' : 'scale-0'}`} ref={this.htmlDivElement}></div>
                        </div>
                    </div>
                    <audio onTimeUpdate={(e) => this.onTimeUpdate(e)} ref={this.htmlAudioElement}>
                        <source src={this.props.recentlyPlayed.previewUrl}/>
                    </audio>
                </LoaderComponent>
            </div>
        )
    }
}

export default connect(mapStateToProps)(RecentlyPlayedComponent);